export const content = {
  pageTitle: "Kid's Books - CHRIS FOLLEN",
  heading: 'our registry(ish)',
  description:
    "We appreciate your kindness in thinking of getting a baby gift for our little one. While we're aiming for a minimalist approach when it comes to baby gear, clothes, and toys, most of you know that that’s not the case for us when it comes to books. We truly don’t need anything, but if you feel compelled to gift something, we'd be thrilled to receive a children's book (or two!) that you think our son would enjoy.  ",
  description2: 'Below is a list of the books we already have.  ',
  description3:
    'We will keep this list up to date, so feel free to send anything not listed here.  Thank you very much!',
  description4: 'If you need a place to shop for books, we love to support our',
  description5: 'local bookstore.',
  description6: '  :)',
  books: [
    {
      title: 'The Beach',
      author: 'Ximo Abadia'
    },
    {
      title: "Giraffes Can't Dance",
      author: 'Giles Andreae & Guy Parker-Rees'
    },
    {
      title: 'The Mitten',
      author: 'Jan Brett'
    },
    {
      title: 'Goodnight Moon',
      author: 'Margaret Wise Brown'
    },
    {
      title: 'The Boy Who Spoke to the Earth',
      author: 'Chris Burkard'
    },
    {
      title: 'The Very Hungry Caterpillar',
      author: 'Eric Carle'
    },
    {
      title: 'The Very Hungry Caterpillar’s Rainbow Colors',
      author: 'Eric Carle'
    },
    {
      title: 'Because Your Daddy Loves You',
      author: 'Andrew Clements'
    },
    {
      title: 'Freight Train',
      author: 'Donald Crews'
    },
    {
      title: 'Fish Alphabet',
      author: 'Beck Feiner'
    },
    {
      title: 'Corduroy',
      author: 'Don Freeman'
    },
    {
      title: 'Goodnight Maine',
      author: 'Adam Gamble & Suwin Chan'
    },
    {
      title: "Don't Worry, Little Crab",
      author: 'Chris Haughton'
    },
    {
      title: 'The Boy Who Would Be King',
      author: 'Ryan Holiday'
    },
    {
      title: 'The Girl Who Would Be Free',
      author: 'Ryan Holiday'
    },
    {
      title: 'Brown Bear, Brown Bear, What Do You See?',
      author: 'Bill Martin Jr & Eric Carle'
    },
    {
      title: 'Guess How Much I Love You',
      author: 'Sam McBratney'
    },
    {
      title: 'Mama Loves You So',
      author: 'Terry Pierce'
    },
    {
      title: 'The Big Orange Splot',
      author: 'Daniel Pinkwater'
    },
    {
      title: 'Alphabet of Waves',
      author: 'Scott Proctor & Rick Albano'
    },
    {
      title: 'Good night, Gorilla',
      author: 'Peggy Rathmann'
    },
    {
      title: 'My Annoying, Irritating, Always-in-the-way Shadow',
      author: 'Ryan Russell'
    },
    {
      title: 'Little Blue Truck',
      author: 'Alice Schertle'
    },
    {
      title: 'Marvin K. Mooney Will You Please Go Now',
      author: 'Dr. Seuss'
    },
    {
      title: 'National Parks of the USA',
      author: 'Kate Siber'
    },
    {
      title: 'Press Here',
      author: 'Herve Tullet'
    },
    {
      title: 'Salty Sleepy Surfery Rhymes',
      author: 'Joe Vickers'
    },
    {
      title: 'Surfer Baby',
      author: 'Joe Vickers'
    }
  ]
};

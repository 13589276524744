export const content = {
  pageTitle: '404 - CHRIS FOLLEN',
  header: '4L04A',
  subheader: "It looks like you've arrived in the middle of nowhere.",
  bodyCopy1: "It's usually a good place to be, but in this case, it isn't.",
  bodyCopy2: 'Best head back ',
  linkText: 'home',
  linkPath: '/',
  bodyCopy3: '.'
};

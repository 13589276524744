import HeroImage from '../../../../assets/blog/blog-1.jpg';

export const content = {
  pageTitle: '30 Lessons - CHRIS FOLLEN',
  title: '30 Lessons from 30 Years',
  date: 'January 12, 2018',
  heroImage: HeroImage,
  imageAltText: 'author',
  intro:
    'Today marks 30 trips around the sun for me. Here are a few thoughts and lessons I’ve picked up along the way.',
  lessons: [
    "Be extremely grateful for everything you have. No matter who you are or what you've been through, you have an incredible amount to be grateful for. Focus on all you have, not the things you lack.",
    'Find the best part. Regardless of the situation, there are some positive aspects. Find them.',
    "Don't complain. You'll be happier and others will like you more. Complaining doesn't change anything – it's a waste of time and energy.",
    "Be kind & help others. It's rewarding. You're only going to walk this road once. You might as well do some good along the way. It's all you'll really leave behind.",
    'Do something rather than be someone. We all have a choice: we can focus on being someone, or on doing something. Being someone is having a title, power, and stuff. Doing something is finding something you believe in and living for that. Doing something is about contributing. Find the thing you want to do, and do it.',
    "Find opportunity in every difficulty. I know first hand that in every difficulty there is plenty of opportunity. A difficult situation offers you the opportunity to learn, to evolve, and to add to your repertoire of life experience. When a difficult situation presents itself we tend to think 'this happened, and it's bad'. However, that is really two separate statements. The first 'this happened' is accurate and objective. The second, though, is subjective and not necessarily accurate. Often times a difficult situation presents possibilities we had never before considered.",
    'You are who you think you are. Believe in yourself.',
    "Exercise every single day. Take care of yourself. Endorphins are good for you. The thing about getting in to shape is that it's about never getting out of shape. Move, it's what you were built for. Focus on flexibility, too, especially as you get older.",
    "Eat clean. You are what you eat. Eat well, and eat real food. You'll feel better. Have a cheat day once a week, though.",
    'Have a couple of vices. Coffee, bourbon, cigars, tequila, gelato, whatever. Winston Churchill said you should never trust a man with no vices. Pick one or three.',
    "Keep a journal. Journal not to look backwards, but to sort out your thoughts and live deliberately and with purpose. It's also an opportunity to practice #1 and #2 on this list – every morning, make a list of a few things you are grateful for, and a few positive things that happened the day before.",
    "Develop a mindfulness practice. Mindfulness too helps one live more deliberately. Don't get caught in the past, don't worry about the future. Be here now. You'll be happier and more productive. I meditate for 20 minutes every morning. If you don't have 20 minutes to meditate, you need three hours. If you don't like sitting still, try a walking meditation, or a task that you can be fully present in (maybe cooking a nice meal, building something, or surfing).",
    "Travel (with purpose). Go places and see things. You'll learn more about the world, and more about yourself. It opens your mind and changes your perspective. But go to learn and grow. It's about the process.",
    "Create. We've become consumers rather than citizens, and our creativity is suffering greatly. Let's fix that. Make something every day. Build something, fix something, play some music, take a photograph, make a meal, plant a garden, write a letter, paint. Build a cabin or make a gingerbread house. Mix a cocktail. I don't care what, but rather than taking something, make something. It's fulfilling and it's better for everyone.",
    "Right now is the right time. People wait their whole lives looking for the right time to do something, and it rarely if ever presents itself. If you want to do something, go do it. I mean now. People tend to regret the things they don't do far more than the things they do. There is a line from the Mahabharata where the king says that the most wondrous thing in the universe is that all around us people are dying, and yet we don't believe it will happen to us. Live like it might happen to you some day. Chances are it will.",
    'You are the average of the five people you associate with most. This is an incredibly true statement, and it applies to your goals, intellect, financial success, physical fitness, virtually everything. Choose your friends wisely, and steer clear of haters and toxic people.',
    'Read some philosophy, and develop one of your own. I had a tough year last year, and while I know it sounds hyperbolic, Stoicism sort of saved my life. Read some philosophy and meditate on it. Then develop your own. If interested in Stoicism specifically, two great books are Letters from a Stoic by Seneca, and Meditations by Marcus Aurelius.',
    "Read books. Fiction or non-fiction, long or short, guilty pleasure or not, I don't care. You should always be in the middle of a good book. Learn from the experiences of others. Bring it with you when you leave the house to keep you occupied when you have time – it's more fulfilling than Snapchat. Also, to be clear, I am talking about a physical book. The kind made of paper. Finally, don't feel guilty about not finishing a book. If you're not into one, quit and find something else. Life's to short to read stuff that doesn't appeal to you, and there are plenty of other great options.",
    "Be in a committed, long-term relationship. Get married. It will be painful and difficult at times. But it's also worth it. Don't settle, but understand that no relationship is perfect and they all take work. And please don't take your partner for granted. Appreciate what you have, every day, and don't argue about stuff that really doesn't matter. It takes a level of selflessness to make a marriage work, and it might be the perfect antidote to the narcissism that's currently taking over our culture.",
    "Think about mortality. Marcus Aurelius asked “are you afraid of death because you won't be able to do this anymore?” For 'this', plug in the nonsense we spend a lot of our lives doing. Don't be afraid of death. But consider it. Know that it is coming, that it is one of the few certainties in life. And then do the absolute best you can with whatever time you have.",
    'Ego is the enemy. This is something I continue to work on. Ego gets you nowhere, and it crushes others. It clouds your judgement and prevents you from seeing things as they really are. Have confidence, but be humble. Ego is self-anointed; confidence is earned.',
    "Don't compare yourself to others. It's a waste of time and energy. All any of us can do is play the hand we've been dealt. Do the absolute best you can, and be satisfied with that. It's the best any of us can hope to do.",
    "Don't waste time being offended. Dr. Seuss said “Be who you are and say what you feel, because in the end those who matter don't mind and those who mind don't matter'. There will always be haters. Just be grateful that you're not one of them.",
    "Get a dog. It's the best friend you'll ever have, will love you unconditionally, and will make you a better person.",
    "Ride a motorcycle. I've yet to meet a problem that a ride won't solve or at least lessen. Trust me on this one. It's also a wonderful mindfulness practice. When riding, you can't help but be truly in the moment.",
    "Prioritize sleep. For a long time I operated on too little sleep. I now aim for 7-8 hours every night. I can say resolutely that quality sleep makes me way happier, way nicer, way calmer, way smarter, and way more productive. Before bed, mix up some honey and apple cider vinegar in a mug with hot water. It's better than sleeping pills and you'll wake up feeling great. I can't explain why, but it works.",
    "Go to bed early and get up early. Nothing that good happens late at night. Have a routine and stick to it. Get up early and greet the day. Get up early and get some stuff done. I get more done before I leave for work than many people do all day. Plus, you can't be in a bad mood watching the sunrise.",
    "Happiness is a choice. Choose it. Lincoln said “Folks are usually about as happy as they make up their minds to be”. Happiness is the only intrinsic good. And it's available to all of us. And it's free. Choose to be happy. It really is that simple. Also, happiness begets success, not the other way around.",
    "Do something that scares you every day. Fear is good for you. Doing things you're afraid of helps you grow. Feel the fear and do it anyway. If nothing else, it increases your tolerance. Buy an outrageous pair of pants and wear them to parties without an explanation. Tomorrow morning at Starbucks, lie down on the floor for 15 seconds then leave and go on with your day.",
    "Don't worry so much. Mark Twain said “I am an old man and I've known a great many troubles, but most of them never happened”. I spent way to much time in my twenties worrying about things that could have happened, but didn't. There is a Stoic phrase 'ta eph'hemin, ta ouk eph'hemin', which means, 'what is up to us, what is not up to us'. Control what you can, and don't worry about the rest. When something 'bad' happens, it's really just a hidden opportunity anyway."
  ],
  backLink: 'Back to journal'
};
